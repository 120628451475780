import React from "react";
import theme from "theme";
import { Theme } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				Velocity Ventures
			</title>
			<meta name={"description"} content={"У Velocity Ventures ми не просто надаємо мотоцикли напрокат - ми даруємо незабутні подорожі. "} />
			<meta property={"og:title"} content={"Velocity Ventures"} />
			<meta property={"og:description"} content={"У Velocity Ventures ми не просто надаємо мотоцикли напрокат - ми даруємо незабутні подорожі. "} />
			<meta property={"og:image"} content={"https://flectarn.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://flectarn.com/img/3665989.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://flectarn.com/img/3665989.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://flectarn.com/img/3665989.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://flectarn.com/img/3665989.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://flectarn.com/img/3665989.png"} />
			<meta name={"msapplication-TileImage"} content={"https://flectarn.com/img/3665989.png"} />
		</Helmet>
		<Components.Header />
		<Components.Contacts />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});